<template>
  <modal class="campaign-edit-modal">
    <div slot="header">
      <h1 class="title">{{ formTitle }}</h1>
    </div>
    <div slot="body">
      <div class="form">
        <div class="form-group">
          <label
            for="input-name"
            class="form-label required">Nombre</label>
          <input
            ref="inputName"
            v-model.trim="inputName"
            @keyup.enter="save"
            id="input-name"
            class="form-input lg"
            :class="{ 'has-error': hasError }"
            type="text"
            autocomplete="off"/>
        </div>

        <div class="form-group">
          <label
            for="input-due-date"
            class="form-label">Día de corte</label>
          <select
            v-model.number="inputDueDate"
            class="form-input sm"
            id="input-due-date">
            <option
              v-for="(option, index) in dueDateOptions"
              :key="index"
              :value="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label
            for="input-active"
            class="form-label">Activa</label>
          <input
            v-model="inputActive"
            id="input-active"
            class="form-input"
            type="checkbox"/>
        </div>

        <div class="form-group">
          <label
            for="input-billable"
            class="form-label">Facturable</label>
          <input
            v-model="inputBillable"
            id="input-billable"
            class="form-input"
            type="checkbox"/>
        </div>
      </div>
    </div>
    <div slot="footer">
      <div class="grid-x align-justify">
        <button @click="save" class="button sm fill primary">Guardar</button>
        <button @click="close" class="button sm fill alert">Cerrar</button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  components: {
    Modal: () => import('@/components/Modal.vue'),
  },
  props: {
    campaign: {
      required: true,
    },
  },
  data() {
    return {
      inputName: '',
      inputDueDate: 1,
      inputActive: false,
      inputBillable: false,
      hasError: false,
      dueDateOptions: [
        {
          label: '1',
          value: 1,
        },
        {
          label: '10',
          value: 10,
        },
        {
          label: '15',
          value: 15,
        },
        {
          label: '20',
          value: 20,
        },
        {
          label: 'Fin de mes',
          value: 30,
        },
      ],
    };
  },
  computed: {
    formTitle() {
      return this.campaign ? `Editar campaña - ${this.campaign.name}` : '';
    },
  },
  mounted() {
    this.inputName = this.campaign.name;
    this.inputDueDate = this.campaign.dueDate;
    this.inputActive = this.campaign.active;
    this.inputBillable = this.campaign.billable;

    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.inputName.focus();
      }, 100);
    });
  },
  watch: {
    inputName() {
      this.hasError = false;
    },
  },
  methods: {
    save() {
      if (this.inputName.length === 0) {
        this.hasError = true;
      } else {
        const data = {
          id: this.campaign.id,
          name: this.inputName,
          dueDate: this.inputDueDate,
          active: this.inputActive,
          billable: this.inputBillable,
        };

        this.$emit('save', data);
        this.hasError = false;
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_campaign-edit-modal.scss';
</style>
